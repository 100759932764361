<template>
  <div class="system-message">
    <p>{{ displayedMessage }}</p>
  </div>
</template>
  
<script>
  export default {
    props: {
      message: {
        type: String,
        required: true,
      },
      typingSpeed: {
        type: Number,
        default: 50, // Speed in milliseconds per character
      },
    },
    data() {
      return {
        displayedMessage: "", // The message to be displayed gradually
      };
    },
    mounted() {
      this.typeMessage(); // Start typing the message when the component is mounted
    },
    methods: {
      typeMessage() {
        let i = 0; // Initialize the index for iterating through the message characters
        const interval = setInterval(() => {
          if (i < this.message.length) { // Check if there are more characters to display
            this.displayedMessage += this.message[i]; // Add the next character to the displayed message
            i++; // Increment the index
          } else {
            clearInterval(interval); // Stop the interval when the entire message is displayed
            this.$emit("message-complete"); // Emit an event to notify the parent component that the message is complete
          }
        }, this.typingSpeed); // Set the typing speed interval based on the provided typingSpeed prop
      },
    },
  };
</script>
