<template>
  <div class="terminal-scene">
    <!-- Display the narrator's message -->
    <p class="narrator-message">[Narrator] {{ scene.dialogue }}</p>
    <ul v-if="!challengeActive">
      <!-- Display the options for the player to choose -->
      <li
        v-for="option in scene.options"
        :key="option.id"
        @click="selectOption(option)"
      >
        <p class="option">{{ option.text }}</p>
      </li>
    </ul>
    <div v-else>
      <span>
        <!-- Display a message when the player chooses the wrong answer -->
        <p>You didn't quite choose the right answer.</p>
        <p>Type the following words within the time frame to pass:</p>
      </span>
      <!-- Display a progress bar for the countdown timer -->
      <progress :value="countdown" max="10"></progress>
      <ul>
        <!-- Display the challenge words that the player needs to type -->
        <li
          v-for="word in challengeWords"
          :key="word"
          :class="{ completed: completedWords.includes(word) }"
        >
          {{ word }}
        </li>
      </ul>
      <p class="answer">
        <span>Challenge Word:</span>
        <!-- Input field for the player to type the challenge word -->
        <input
          v-model="inputText"
          @input="startTimer"
          @keyup.enter="checkWord"
          placeholder="Re-type the word here"
          autofocus="true"
          autocomplete="off"
        />
      </p>
      <p v-if="challengeFailed">You failed the challenge! Restarting...</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    scene: Object,
  },
  data() {
    return {
      challengeActive: false,
      challengeWords: [],
      completedWords: [],
      inputText: "",
      countdown: 10,
      challengeFailed: false,
      timerStarted: false,
      countdownTimer: null,
    };
  },
  watch: {
    // Reset the component state when the scene changes
    scene: {
      immediate: true,
      handler() {
        this.resetState();
      },
    },
  },
  methods: {
    resetState() {
      // Reset all the component data to initial values
      this.challengeActive = false;
      this.challengeWords = [];
      this.completedWords = [];
      this.inputText = "";
      this.countdown = 10;
      this.challengeFailed = false;
      this.timerStarted = false;
      clearInterval(this.countdownTimer);
    },
    selectOption(option) {
      if (option.type === "challenge") {
        // Start the challenge if the selected option is a challenge
        this.startChallenge(option.challenge_words);
      } else {
        // Emit the selected option to the parent component
        this.$emit("option-selected", option);
      }
    },
    startChallenge(words) {
      // Initialize the challenge with the provided words
      this.challengeActive = true;
      this.challengeWords = [...words];
      this.completedWords = [];
      this.inputText = "";
      this.countdown = 10;
      this.challengeFailed = false;
      this.timerStarted = false;
    },
    startTimer() {
      if (!this.timerStarted) {
        // Start the countdown timer if it hasn't been started yet
        this.timerStarted = true;
        this.countdownTimer = setInterval(() => {
          this.countdown--;
          if (this.countdown <= 0) {
            // Fail the challenge if the countdown reaches zero
            this.failChallenge();
          }
        }, 1000);
      }
    },
    checkWord() {
      const word = this.inputText.trim();
      if (this.challengeWords.includes(word) && !this.completedWords.includes(word)) {
        // Mark the word as completed if it matches the challenge word
        this.completedWords.push(word);
        if (this.completedWords.length === this.challengeWords.length) {
          // Complete the challenge if all the challenge words have been typed
          this.completeChallenge();
        }
      }
      this.inputText = ""; // Clear the input field
    },
    completeChallenge() {
      clearInterval(this.countdownTimer);
      // Emit the "pass" option to the parent component to proceed to the next scene
      this.$emit("option-selected", { type: "pass" });
    },
    failChallenge() {
      clearInterval(this.countdownTimer);
      this.challengeFailed = true;
      setTimeout(() => {
        // Emit the "fail" option to the parent component to restart the game
        this.$emit("option-selected", { type: "fail" });
      }, 2000);
    },
  },
  beforeUnmount() {
    clearInterval(this.countdownTimer);
  },
};
</script>
