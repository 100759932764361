<template>
  <div class="terminal-end">
    <!-- Display the end message -->
    <p>{{ message }}</p>
    <!-- Restart button -->
    <button class="restart" @click="$emit('restart')">Restart</button>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
  },
};
</script>