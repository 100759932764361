<template>
  <div class="terminal-input">
    <!-- Display the welcome message -->
    <div class="system-message">
      <p>{{ welcomeMessage }}</p>
    </div>
    <p class="answer">
      <span>Adventure Subject:</span>
      <!-- Input field for the adventure subject -->
      <input
        v-model="subject"
        id="subject"
        type="text"
        placeholder="Enter your adventure"
        autofocus
        autocomplete="off"
        @keyup.enter="submitSubject"
      />
    </p>
  </div>
</template>

<script>
import WelcomeMessage from "./WelcomeMessage.vue";

export default {
  data() {
    return {
      subject: "", // Initialize the subject as an empty string
      welcomeMessage: WelcomeMessage.data().message, // Get the welcome message from WelcomeMessage component
    };
  },
  methods: {
    submitSubject() {
      if (this.subject) { // Check if the subject is not empty
        this.$emit("subject-submitted", this.subject); // Emit the subject to the parent component
      }
    },
  },
};
</script>
