<template>
  <div class="system-message">
    <!-- Display the message if it exists -->
    <p v-if="displayedMessage">{{ displayedMessage }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: "[System] Welcome to TaleSpin AI! Start by entering your adventure subject.",
      displayedMessage: "",
    };
  },
  mounted() {
    // Start typing the message when the component is mounted
    this.typeMessage();
  },
  methods: {
    typeMessage() {
      let i = 0;
      const interval = setInterval(() => {
        if (i < this.message.length) {
          // Add each character of the message to the displayed message
          this.displayedMessage += this.message[i];
          i++;
        } else {
          // Stop typing when the entire message has been displayed
          clearInterval(interval);
          // Emit an event to indicate that the message has been completed
          this.$emit("message-complete", this.message);
        }
      }, 50);
    },
  },
};
</script>
