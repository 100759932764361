<template>
  <h1 class="title">TaleSpin AI</h1>
  <div class="terminal">
    <div class="header">
      <div class="buttons">
        <span class="button red"></span>
        <span class="button yellow"></span>
        <span class="button green"></span>
      </div>
    </div>
    <div class="content">
      <!-- Display the WelcomeMessage component when step is 'welcome' -->
      <WelcomeMessage v-if="step === 'welcome'" @message-complete="nextStep" />
      <!-- Display the AdventureInput component when step is 'input' -->
      <AdventureInput v-if="step === 'input'" @subject-submitted="prepareStory" />
      <!-- Display the GeneratingMessage component when step is 'generating' -->
      <GeneratingMessage
        v-if="step === 'generating'"
        :message="generatingMessage"
        @message-complete="startAdventure"
      />
      <!-- Display the GameScene component when step is 'game' -->
      <GameScene
        v-if="step === 'game'"
        :scene="currentScene"
        @option-selected="handleOption"
      />
      <!-- Display the EndMessage component when step is 'end' -->
      <EndMessage
        v-if="step === 'end'"
        :message="endMessage"
        @restart="restartGame"
      />
      <hr />
    </div>
  </div>
</template>

<script>
import WelcomeMessage from "./components/WelcomeMessage.vue";
import AdventureInput from "./components/AdventureInput.vue";
import GeneratingMessage from "./components/GeneratingMessage.vue";
import GameScene from "./components/GameScene.vue";
import EndMessage from "./components/EndMessage.vue";
import axios from "axios";

axios.defaults.baseURL = "https://backend.casdoorn.nl/talespin-api";

export default {
  data() {
    return {
      step: "welcome",
      currentScene: null,
      generatingMessage: "[System] Generating your story... Please wait.",
      subject: "",
      endMessage: "",
    };
  },
  components: {
    WelcomeMessage,
    AdventureInput,
    GeneratingMessage,
    GameScene,
    EndMessage,
  },
  methods: {
    nextStep() {
      // Move to the 'input' step
      this.step = "input";
    },
    prepareStory(subject) {
      // Store the subject and move to the 'generating' step
      this.subject = subject;
      this.step = "generating";
    },
    async startAdventure() {
      try {
        // Send a POST request to start the adventure
        await axios.post("/adventure", { subject: this.subject });
        // Get the first scene
        const response = await axios.get("/scenes/1");
        this.currentScene = response.data;
        // Move to the 'game' step
        this.step = "game";
      } catch (error) {
        console.error("Error starting adventure:", error);
      }
    },
    async handleOption(option) {
      if (option.type === "pass") {
        const nextSceneId = this.currentScene.scene_id + 1;

        try {
          // Get the next scene based on the scene ID
          const response = await axios.get(`/scenes/${nextSceneId}`);
          if (response.data) {
            this.currentScene = response.data;
          } else {
            // No more scenes; end the adventure
            this.endMessage = "Congratulations! You have completed the adventure!";
            this.step = "end";
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            // Handle no scenes left
            this.endMessage = "Congratulations! You have completed the adventure!";
            this.step = "end";
          } else {
            console.error("Error fetching next scene:", error);
          }
        }
      } else if (option.type === "fail") {
        // The adventure has failed
        this.endMessage = "You failed the adventure. Better luck next time!";
        this.step = "end";
      }
    },
    restartGame() {
      // Restart the game by moving back to the 'input' step and resetting the current scene
      this.step = "input";
      this.currentScene = null;
    },
  },
};
</script>
